:root {
  font-family: sans-serif;
}

body {
  background-color: #eee;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .card {
  background-color: #fff;
  border-radius: 16px;
  margin: 15px;
  padding: 15px;
  width: 350px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
}

.card h2 {
  word-break: break-all;
}
.card .type {
  font-size: .8rem;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
}

.card .record {
  margin-bottom: 10px;
  word-break: break-all;
}